import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classname from 'classname';

import LoadingSpinner from 'components/LoadingSpinner';
import TestSuitePublishModal from 'components/TestSuitePublishModal';
import ConfirmationModal from 'components/ConfirmationModal';
import MetafieldsModal from 'components/MetafieldsModal';
import ReactTooltip from 'react-tooltip';

import pluralize from 'utils/pluralize';
import {
  getTestSuiteDetails,
  updateTestSuite,
  getMetafields,
  runTestSuite,
  removeTestSuite,
} from 'store/actions/test_suites_actions';
import NotificationField from '../NotificationField';
import ScheduleModal from '../../containers/TestSuites/ScheduleModal';
import '../Tooltip/Tooltip.sass';
function TestSuiteRow({ suite, roles, userInfo }) {
  // state
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suiteData, setSuiteData] = useState({});
  const [modalData, setModalData] = useState({});
  const [metafields, setMetafields] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [executionName, setExecutionName] = useState('');
  const [jiraTestCycle, setJiraTestCycle] = useState('');
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState('');
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [isTestSuiteIdCopied, setIsTestSuiteIdCopied] = useState(false);
  // refs
  const TestSuiteLiRef = useRef(null);
  // dispatch
  const dispatch = useDispatch();
  // methods
  const userCan = (action) => {
    const ROLES = {
      reader: ['view', 'run'],
      editor: ['view', 'edit', 'run'],
      admin: ['view', 'edit', 'share', 'delete', 'run', 'schedule'],
      owner: ['view', 'edit', 'share', 'delete', 'run', 'schedule'],
    };

    if (ROLES[suite.role.toLowerCase()]) {
      return ROLES[suite.role.toLowerCase()].includes(action);
    }

    return false;
  };

  const countTestCases = () => {
    let numberOfTestCases = 0;

    suiteData.payload.category.forEach((category) => {
      if (category.subcategory) {
        category.subcategory.forEach((subcategory) => {
          numberOfTestCases += subcategory.testcases.length;
        });
      }
    });

    return numberOfTestCases;
  };
  const findTestCase = (categoryid, subcategoryid, testcaseid) => {
    const { payload } = suiteData;

    if (payload) {
      const category = payload.category.find(
        (category) => category.categoryid === categoryid
      );

      if (category) {
        const subcategory = category.subcategory.find(
          (subcategory) => subcategory.subcategoryid === subcategoryid
        );

        if (subcategory) {
          const testcase = subcategory.testcases.find(
            (testcase) => testcase.testcaseid === testcaseid
          );

          return testcase;
        }
      }
    }

    return false;
  };
  const removeTestCase = () => {
    const { payload } = suiteData;
    let payloadChanged = false;

    if (payload) {
      const category = payload.category.find(
        (category) => category.categoryid === modalData.categoryid
      );

      if (category) {
        const subcategory = category.subcategory.find(
          (subcategory) => subcategory.subcategoryid === modalData.subcategoryid
        );

        if (subcategory) {
          const testcaseIndex = subcategory.testcases.findIndex(
            (testcase) => testcase.testcaseid === modalData.testcaseid
          );

          if (testcaseIndex >= 0) {
            subcategory.testcases.splice(testcaseIndex, 1);

            payloadChanged = true;
          }
        }

        if (subcategory.testcases.length === 0) {
          const subcategoryIndex = category.subcategory.findIndex(
            (s) => s.subcategoryid === subcategory.subcategoryid
          );

          if (subcategoryIndex >= 0) {
            category.subcategory.splice(subcategoryIndex, 1);
          }
        }
      }

      if (payloadChanged) {
        setIsModalLoading(true);

        // send put request
        dispatch(updateTestSuite(suiteData.id, payload))
          .then((response) => {
            // fetch test suite details again
            getTestSuiteDetails(response.data.testsuiteid);
          })
          .then((_) => {
            setIsModalLoading(false);
            toggleModal();
          });
      }
    }
  };
  const loadMetafields = (data) => {
    getMetafields(data).then((metafields) => {
      setMetafields(metafields);
    });
  };
  // user actions
  const toggleModal = (e) => {
    if (e) {
      const { target } = e;

      if (target.dataset && target.dataset.modalName) {
        setModalData({
          categoryid: target.dataset.categoryId,
          name: target.dataset.modalName,
          subcategoryid: target.dataset.subcategoryId,
          testcaseid: target.dataset.testcaseId,
          testsuiteid: target.dataset.testsuiteid,
          emailrecipients: target.dataset.emailrecipients,
          visible: true,
          executionName: '',
        });
      } else {
        setModalData({
          modal: null,
          visible: false,
        });
      }
    } else {
      setModalData({
        modal: null,
        visible: false,
      });
    }
  };

  const onNotificationFieldChange = (value) => {
    setNotificationEmail(value);
  };

  const onExecutionNameChange = (e) => {
    setExecutionName(e.target.value);
  };

  const onRunClick = () => {
    const { testsuiteid } = modalData;
    // dispatch runTestSuite
    setIsRunning(true);
    setNotificationEmail(suite.emailrecipients || userInfo.emailid);
    dispatch(
      runTestSuite(testsuiteid, {
        executionname: executionName,
        notificationemail: notificationEmail,
        jiraTestCycleId: jiraTestCycle,
      })
    ).then(() => {
      setIsRunning(false);
    });
    toggleModal();
  };

  const onTestSuiteNameClick = (e) => {
    const id = TestSuiteLiRef.current.dataset.testsuiteid;

    setIsExpanded(!isExpanded);

    if (!isExpanded) {
      setIsLoading(true);

      // get test suite detail
      getTestSuiteDetails(id).then((response) => {
        setIsLoading(false);
        setSuiteData(response.data);

        loadMetafields(response.data);
      });
    }

    e.stopPropagation();
  };
  const onCustomizeSubmit = (payload) => {
    if (suiteData.payload) {
      const category = suiteData.payload.category.find(
        (category) => category.categoryid === modalData.categoryid
      );

      if (category) {
        const subcategory = category.subcategory.find(
          (subcategory) => subcategory.subcategoryid === modalData.subcategoryid
        );

        if (subcategory) {
          const testcaseIndex = subcategory.testcases.findIndex(
            (testcase) => testcase.testcaseid === modalData.testcaseid
          );

          if (testcaseIndex >= 0) {
            const testcase = {
              ...subcategory.testcases[testcaseIndex],
              ...payload[modalData.testcaseid],
            };

            subcategory.testcases.splice(testcaseIndex, 1, testcase);

            // send put request
            dispatch(updateTestSuite(suiteData.id, suiteData.payload))
              .then((response) => {
                // fetch test suite details again
                getTestSuiteDetails(response.data.testsuiteid);
              })
              .then((_) => {
                toggleModal();
              });
          }
        }
      }
    }
  };
  // render
  const renderModal = () => {
    if (modalData.visible) {
      let modalProps = {
        onClose: toggleModal,
      };

      switch (modalData.name) {
        case 'run':
          modalProps = {
            ...modalProps,
            onConfirm: onRunClick,
            title: 'Execute test suite',
            submitButtonText: 'Execute',
          };

          return (
            <ConfirmationModal {...modalProps}>
              <div className="modal-form modal-content-wrapper">
                <div className="modal-form-common-fields">
                  <div className="col-sm-12 note">
                    <label>
                      Test suite <strong>{suite.testsuitename}</strong> having{' '}
                      <strong>{suite.testcasecount}</strong> test cases.
                    </label>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 form-group" key="executionname">
                      <label htmlFor="executionname">
                        Execution name{' '}
                        <span className="optional">Optional</span>
                      </label>
                      <input
                        className="form-control"
                        name="executionname"
                        type="text"
                        value={executionName}
                        onChange={onExecutionNameChange}
                      />
                    </div>
                    <div className="col-sm-4 form-group" key="jiratestcycle">
                      <label htmlFor="jiraTestCycle">
                        Jira Test Cycle{' '}
                        <span className="optional">Optional</span>
                      </label>
                      <input
                        className="form-control"
                        name="jiraTestCycle"
                        type="text"
                        value={jiraTestCycle}
                        onChange={(e) => setJiraTestCycle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 form-group" key="notificationemail">
                    <NotificationField
                      value={notificationEmail || userInfo.emailid}
                      notifyAllValue={suite.emailrecipients || ''}
                      name="notificationemail"
                      onChange={onNotificationFieldChange}
                      loggedInUserEmail={userInfo.emailid}
                    />
                  </div>
                </div>
              </div>
            </ConfirmationModal>
          );
        case 'share':
          return (
            <TestSuitePublishModal
              onClose={toggleModal}
              userInfo={userInfo}
              roles={roles.filter((r) => r.rolename !== 'Owner')}
              testsuiteid={modalData.testsuiteid}
              userlist={suite.userlist}
            />
          );
        case 'remove-testcase':
          modalProps = {
            ...modalProps,
            title: 'Remove test case',
            submitButtonText: 'Remove',
            message:
              'Proceeding will remove the selected test case from the test suite. This action cannot be undone.',
            onConfirm: removeTestCase,
            loading: isModalLoading,
          };

          return <ConfirmationModal {...modalProps} />;
        case 'delete-testsuite':
          return (
            <ConfirmationModal
              title="Remove test suite"
              message="Proceeding will remove the selected test suite. This action cannot be undone."
              submitButtonText="Remove test suite"
              onConfirm={handleDeleteTestSuite}
              loading={isModalLoading}
              onClose={toggleModal}
            />
          );
        case 'customize-testcase':
          const testcase = findTestCase(
            modalData.categoryid,
            modalData.subcategoryid,
            modalData.testcaseid
          );
          const fields =
            metafields[modalData.subcategoryid][testcase.testype] ||
            metafields[modalData.subcategoryid].default;

          if (fields) {
            if (!testcase.id) {
              testcase.id = testcase.testcaseid;
            }

            fields.forEach((field) => {
              if (field.field === 'lineitems') {
                field.type = 'lineitems';
              }
            });

            modalProps = {
              ...modalProps,
              title: 'Customize test case',
              metafields: fields,
              elements: [testcase],
              environment: testcase.environment,
              onSubmit: onCustomizeSubmit,
              valueFromElements: true,
            };

            modalProps.hiddenFields = [
              'env',
              'testcasename',
              'displayname',
              'description',
              'testcasedir',
              'tools',
              'os',
              'testClass',
              'testGroup',
              'testMethod',
              'wiki',
              'notsupportedenv',
              'parameters',
            ];

            return <MetafieldsModal {...modalProps} />;
          }

          break;
        default:
          return null;
      }
    }

    return null;
  };
  const renderTestSuiteDetails = () => {
    if (suiteData.payload) {
      return suiteData.payload.category.map((category) => {
        if (category.subcategory) {
          const subcategories = category.subcategory.map((subcategory) => {
            const testcases = subcategory.testcases.map((testcase) => {
              const commonProps = {
                type: 'button',
                'data-category-id': category.categoryid,
                'data-subcategory-id': subcategory.subcategoryid,
                'data-testcase-id': testcase.testcaseid,
                onClick: toggleModal,
              };
              let buttons = [];

              if (userCan('edit')) {
                const metaKey = testcase.projectname || testcase.subcategoryid;
                const metaValues = metafields[metaKey] || {};
                const numberOfValues = Object.keys(metaValues).length;

                buttons.push(
                  <button
                    key="remove"
                    {...commonProps}
                    className="btn btn-link text-danger"
                    data-modal-name="remove-testcase"
                  >
                    Remove
                  </button>
                );

                buttons.push(
                  <button
                    {...commonProps}
                    className="btn btn-link"
                    data-modal-name="customize-testcase"
                    disabled={numberOfValues === 0}
                  >
                    Customize
                  </button>
                );
              }

              return (
                <li>
                  <div>{testcase.name}</div>
                  <div className="test-suite-row-actions">{buttons}</div>
                </li>
              );
            });
            let categoryName;

            if (category.categoryname) {
              categoryName = `${category.categoryname} > ${subcategory.subcategoryname}`;
            } else {
              categoryName = `${subcategory.subcategoryid} > ${subcategory.subcategoryname}`;
            }

            return (
              <li>
                <span>{categoryName}</span>

                <ul className="test-suite-row-testcases">{testcases}</ul>
              </li>
            );
          });

          return (
            <ul className="test-suite-row-subcategories">{subcategories}</ul>
          );
        }

        return null;
      });
    }

    if (isLoading) {
      return <LoadingSpinner text="Loading test suite" />;
    }

    return null;
  };
  const handleDeleteTestSuite = () => {
    // send delete request
    setIsModalLoading(true);
    dispatch(removeTestSuite(suite.testsuiteid)).then(() => {
      setIsModalLoading(false);
      toggleModal();
    });
  };

  const liProps = {
    className: classname({
      'test-suite-row': true,
      expanded: isExpanded,
    }),
  };
  const testSuiteDetails = renderTestSuiteDetails();
  const modal = renderModal();
  let shared = null;
  let numberOfTestCases = suite.testcasecount;
  let actions = [];
  let sharedText = '';

  if (suite.userlist && suite.userlist.users.length) {
    if (userInfo && userInfo.emailid === suite.createdby) {
      const numberOfUsers = suite.userlist.users.length;

      sharedText = `Shared to ${numberOfUsers} ${pluralize(
        numberOfUsers,
        'person',
        'people'
      )}`;
    } else {
      sharedText = `Shared by ${suite.createdby}`;
    }

    shared = (
      <span>
        <ReactTooltip
          id={`shared-${suite.testsuiteid}`}
          place="right"
          effect="solid"
          className="tooltip"
        >
          <span>{sharedText}</span>
        </ReactTooltip>
        <span>
          <i
            className="bi bi-people"
            data-tip
            data-for={`shared-${suite.testsuiteid}`}
          ></i>
        </span>{' '}
      </span>
    );
  }

  if (suiteData.payload) {
    numberOfTestCases = countTestCases();
  }

  // actions
  if (userCan('edit')) {
    actions.push(
      <NavLink
        key="edit"
        to={`/testsuites/${suite.testsuiteid}`}
        className="dropdown-item"
        disabled={isRunning}
      >
        Edit
      </NavLink>
    );
  }

  if (userCan('share')) {
    actions.push(
      <button
        key="share"
        type="button"
        className="dropdown-item"
        data-modal-name="share"
        data-testsuiteid={suite.testsuiteid}
        onClick={toggleModal}
        disabled={isRunning}
      >
        Share
      </button>
    );
  }

  if (userCan('schedule')) {
    actions.push(
      <button
        key="schedule"
        type="button"
        className="dropdown-item"
        data-modal-name="schedule"
        data-testsuiteid={suite.testsuiteid}
        onClick={() => setOpenScheduleModal(true)}
        disabled={isRunning}
      >
        Schedule
      </button>
    );
  }
  if (userCan('delete')) {
    actions.push(
      <button
        key="delete"
        type="button"
        className="dropdown-item"
        data-modal-name="delete-testsuite"
        data-testsuiteid={suite.testsuiteid}
        onClick={toggleModal}
        disabled={isRunning}
      >
        Delete
      </button>
    );
  }
  const testsuiteActions = (
    <div className="test-case-actions">
      <button
        type="button"
        id="test-case-secondary-actions"
        data-toggle="dropdown"
      >
        <i className="bi bi-three-dots"></i>
      </button>

      <div
        className="dropdown-menu"
        aria-labelledby="test-case-secondary-actions"
      >
        {actions.map((button) => button)}
      </div>
    </div>
  );

  const onCopyTestSuiteId = () => {
    navigator.clipboard.writeText(suite.testsuiteid);
    setIsTestSuiteIdCopied(true);
    setTimeout(() => {
      setIsTestSuiteIdCopied(false);
    }, 2000);
  };

  return (
    <>
      <li {...liProps}>
        <div className="test-suite-info">
          <span
            className="test-suite-name"
            ref={TestSuiteLiRef}
            data-testsuiteid={suite.testsuiteid}
            onClick={onTestSuiteNameClick}
          >
            <img
              alt=""
              className="test-suite-expand"
              src="https://testing-hub-static-assets.s3.amazonaws.com/images/icons/arr-d.png"
            />
            {suite.testsuitename}{' '}
            <ReactTooltip
              id={suite.testsuiteid}
              place="top"
              effect="solid"
              className="tooltip"
            >
              {isTestSuiteIdCopied ? (
                'Copied!'
              ) : (
                <>
                  <p>Click on the icon to copy Test suite Id</p>
                  <p>{`Testsuite id: ${suite.testsuiteid}`}</p>
                  <p>{`Created by: ${suite.createdby}`}</p>
                  <p>{`Created date: ${suite.createddate.split('.')[0]}`}</p>
                  <p>{`Modified date: ${suite.modifieddate.split('.')[0]}`}</p>
                </>
              )}
            </ReactTooltip>
            <span>
              <i
                className="bi bi-info-circle"
                data-tip
                data-for={suite.testsuiteid}
                onClick={onCopyTestSuiteId}
              ></i>{' '}
            </span>
            {/* </div> */}
            {shared}
            {suite.scheduledtime && (
              <>
                <ReactTooltip
                  id={`schedule-${suite.testsuiteid}`}
                  place="top"
                  effect="solid"
                  className="tooltip"
                >
                  <p>{`Start date: ${suite.start_date}`}</p>
                  <p>{`End date: ${suite.end_date}`}</p>
                </ReactTooltip>
                <span>
                  {' '}
                  <i
                    className="bi bi-clock"
                    data-tip
                    data-for={`schedule-${suite.testsuiteid}`}
                  ></i>
                </span>
              </>
            )}
          </span>
          <span>{suite.category}</span>
          <span>{suite.environment}</span>
          <span>{numberOfTestCases}</span>
          <span className="test-suite-actions">
            {userCan('run') && (
              <button
                key="run"
                type="button"
                className="btn btn-link btn-link-primary"
                data-emailrecipients={suite.emailrecipients}
                data-modal-name="run"
                data-testsuiteid={suite.testsuiteid}
                onClick={toggleModal}
                disabled={isRunning}
              >
                {isRunning ? 'Running' : 'Run'}
              </button>
            )}
            {testsuiteActions}
          </span>
        </div>

        <div className="test-suite-details">{testSuiteDetails}</div>

        {modal}
      </li>
      {openScheduleModal && (
        <ScheduleModal
          open={openScheduleModal}
          onClose={() => setOpenScheduleModal(false)}
          testSuiteId={suite.testsuiteid}
          schedule={{
            disabled: suite.isdisabled,
            cronExpression: suite.scheduledtime,
            dateStart: suite.start_date,
            dateEnd: suite.end_date,
          }}
        />
      )}
    </>
  );
}

TestSuiteRow.propTypes = {
  suite: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

export default TestSuiteRow;
