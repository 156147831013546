import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { postGlobalFields } from '../../utils/api.service';
import { addNewNotification } from 'store/actions/notification_actions';
import { addGlobalFields } from '../../store/actions/hosting_actions';

const uniqueKeys = (array, path) => {
  const seen = new Set();
  if (!array) return true;
  return array.every((item) => {
    const key = path ? item[path] : item;
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};

const validationSchema = Yup.object().shape({
  fields: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string()
          .required('Key is required')
          .test('unique', 'Keys must be unique', function (value) {
            const { fields } = this.options.context;
            return uniqueKeys(fields, 'key');
          }),
        type: Yup.string().required('Type is required'),
        value: Yup.string().when('type', {
          is: 'string',
          then: (schema) => schema.required('Value is required'),
        }),
        options: Yup.array().when('type', {
          is: 'choices',
          then: (schema) =>
            Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.string().required('This value is required'),
                })
              )
              .test('unique', 'Choices must be unique', function (options) {
                return uniqueKeys(options, 'value');
              }),
        }),
        default: Yup.string().when('type', {
          is: 'choices',
          then: (schema) => schema.required('Default value should be selected'),
        }),
      })
    )
    .required('At least one field is required')
    .min(1, 'At least one field is required'),
});

const GlobalFieldsModal = ({ isOpen, onClose = () => {}, workstream, org }) => {
  const globalFields = useSelector((state) => state.hosting.globalFields);
  const formikRef = useRef();
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const {
        data: { status, message },
      } = await postGlobalFields(org, workstream, values);
      if (status === 'success') {
        dispatch(addNewNotification(message, 'success'));
        dispatch(addGlobalFields(values));
      } else {
        dispatch(addNewNotification(message, 'danger'));
      }
      onClose();
    } catch (error) {
      const errorDetail =
        error.response?.data?.detail || 'A network error occurred';
      dispatch(addNewNotification(errorDetail, 'danger'));
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return (
    <Modal
      title="Manage global fields"
      submitButtonText={'Save'}
      submitEnabled
      onClose={onClose}
      onSubmit={() => {
        if (formikRef.current) {
          formikRef.current.submitForm();
          setIsSubmitted(true);
        }
      }}
      loading={loading}
    >
      <Formik
        initialValues={globalFields}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={isSubmitted}
        validateOnBlur={isSubmitted}
        innerRef={formikRef}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Form className="needs-validation" noValidate>
            <FieldArray name="fields">
              {({ insert, remove, push }) => (
                <div>
                  {values.fields.length > 0 &&
                    values.fields.map((field, index) => (
                      <div className="global-fields__content" key={index}>
                        <section
                          className={`global-fields__controls ${
                            field.type === 'choices'
                              ? 'form-group'
                              : 'string-group'
                          }`}
                        >
                          <div className="col-sm-4">
                            <Field
                              className={`form-control ${
                                errors.fields?.[index]?.key ||
                                errors.fields?.[index]?.options ||
                                errors.fields?.[index]?.default
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name={`fields.${index}.key`}
                              placeholder="Key"
                            />
                            <ErrorMessage
                              name={`fields.${index}.key`}
                              component="div"
                              className="invalid-feedback"
                            />
                            {!Array.isArray(
                              errors.fields?.[index]?.options
                            ) && (
                              <ErrorMessage
                                name={`fields.${index}.options`}
                                component="div"
                                className="invalid-feedback"
                              />
                            )}
                            <ErrorMessage
                              name={`fields.${index}.default`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <Field
                            className="form-control"
                            as="select"
                            name={`fields.${index}.type`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="string">String</option>
                            <option value="choices">Choices</option>
                          </Field>
                          <ErrorMessage
                            name={`fields.${index}.type`}
                            component="div"
                            className="invalid-feedback"
                          />
                          {field.type === 'string' && (
                            <div className="col-sm-4">
                              <Field
                                className={`form-control ${
                                  errors.fields?.[index]?.value
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name={`fields.${index}.value`}
                                placeholder="Value"
                              />
                              <ErrorMessage
                                name={`fields.${index}.value`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          )}
                          <button
                            className="btn btn-outline-danger"
                            type="button"
                            onClick={() => {
                              remove(index);
                              // validate on remove
                              formikRef.current.validateForm();
                            }}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </section>
                        {field.type === 'choices' && (
                          <FieldArray name={`fields.${index}.options`}>
                            {({ remove, push, form }) => (
                              <div className="choices-group">
                                {field.options.length > 0 &&
                                  field.options.map((option, optionIndex) => (
                                    <div
                                      className="choices col-sm-3"
                                      key={optionIndex}
                                    >
                                      <div className="choices-feedback">
                                        <Field
                                          className={`form-control ${
                                            errors.fields?.[index]?.options?.[
                                              optionIndex
                                            ]?.value
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          name={`fields.${index}.options.${optionIndex}.value`}
                                          placeholder="Choice"
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            form.handleChange(e); // Update Formik state
                                            form.setFieldValue(
                                              `fields.${index}.default`,
                                              newValue
                                            );
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`fields.${index}.options.${optionIndex}.value`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <Field
                                        type="radio"
                                        id={`default-${index}-${optionIndex}`}
                                        className="default-radio"
                                        name={`fields.${index}.default`}
                                        value={option.value}
                                        checked={field.default === option.value}
                                        onChange={() => {
                                          form.setFieldValue(
                                            `fields.${index}.default`,
                                            option.value
                                          );
                                        }}
                                      />
                                      <label
                                        htmlFor={`default-${index}-${optionIndex}`}
                                        className="default-label"
                                      >
                                        <i className="bi bi-check"></i>
                                      </label>
                                      <button
                                        className="btn btn-outline-danger"
                                        type="button"
                                        onClick={() => {
                                          // check if the option is default
                                          // set default to empty if it is
                                          if (field.default === option.value) {
                                            form.setFieldValue(
                                              `fields.${index}.default`,
                                              ''
                                            );
                                          }
                                          remove(optionIndex);
                                        }}
                                      >
                                        <i className="bi bi-trash"></i>
                                      </button>
                                    </div>
                                  ))}
                                <div className="col-sm-3">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => push({ value: '' })}
                                  >
                                    Add choice
                                  </button>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        )}
                      </div>
                    ))}
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      push({
                        key: '',
                        type: 'string',
                        value: '',
                        options: [''],
                      })
                    }
                  >
                    Add field
                  </button>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default GlobalFieldsModal;
