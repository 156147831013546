import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import LoadingSpinner from 'components/LoadingSpinner';
import downloadFile from 'utils/downloadFile';

import { addNewNotification } from 'store/actions/notification_actions';

function BulkUploadModal(props) {
  // state
  const [dragOver, setDragOver] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [csvFile, setCSVFile] = useState();
  // refs
  const fileInput = React.createRef();
  // methods
  const onDragAndDropClick = () => {
    fileInput.current.click();
  };
  const onDragOver = (e) => {
    e.preventDefault();

    setDragOver(true);
  };
  const onDragLeave = () => setDragOver(false);
  const onDrop = (e) => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          setCSVFile(e.dataTransfer.items[i].getAsFile());
        }
      }
    }
  };
  const onDownloadTemplateClick = () => {
    downloadCSVFile([...props.fields, 'name'], 'template.csv');
  };
  const onChange = (e) => {
    setCSVFile(e.target.files[0]);
  };
  const onSubmit = () => {
    readFile(csvFile);
  };
  const readFile = (file) => {
    const reader = new FileReader();

    setIsProcessing(true);

    reader.readAsText(file);
    reader.onload = () => {
      processCSVFile(reader.result);
    };
  };
  const resolveColumnName = (name) => {
    // exceptions
    if (name?.includes('vatnumber')) {
      return 'vatnumber';
    }

    return name;
  };
  const processCSVFile = (csv) => {
    const payload = [];
    const rows = csv.split('\n');
    const columns = rows[0].split(',');

    for (let index = 1; index <= rows.length; index++) {
      const row = rows[index];

      if (row) {
        const cells = row.split(',');
        const testcase = {};

        cells.forEach((cell, index) => {
          const cellName = resolveColumnName(columns[index]);

          if (cellName) {
            let cellValue = cell.trim();

            if (
              cellName === 'testdata' ||
              cellName.includes('skulist') ||
              cellName.includes('priceid')
            ) {
              cellValue = [];

              cell
                .trim()
                .split('|')
                .forEach((keyValuePair) => {
                  const [key, value] = keyValuePair.split('=');

                  if (key) {
                    // default value is 1
                    cellValue.push([key.trim(), value ? value.trim() : 1]);
                  }
                });
            } else if (cellName === 'parameters') {
              cellValue = {};

              cell
                .trim()
                .split('|')
                .forEach((keyValuePair) => {
                  const [key, value] = keyValuePair.split('=');

                  if (key) {
                    // default value is 1
                    cellValue[key.trim()] = value ? value.trim() : 1;
                  }
                });
            }

            testcase[cellName] = cellValue;
          }
        });

        // testdata will de added later on
        testcase.testdata = {};

        payload.push(testcase);
      }
    }

    props
      .onSubmit(payload)
      .then((response) => {
        if (response.data) {
          props.addNewNotification(response.data.message, 'success');
        }
      })
      .catch((response) => {
        props.addNewNotification(response.message, 'danger');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };
  const downloadCSVFile = (rows, filename = 'file.csv') => {
    if (rows.length) {
      const csvFile = 'data:text/csv;charset=utf-8,' + rows.join(',');
      const csvURI = encodeURI(csvFile);

      downloadFile.fromContent(filename, csvURI);
    }
  };
  // variables
  const modalProps = {
    title: 'Upload test cases',
    onClose: props.onClose,
    onSubmit,
  };
  const dragAndDropProps = {
    className: classname({
      'drag-and-drop': true,
      over: dragOver,
    }),
    onClick: onDragAndDropClick,
    onDragOver,
    onDragLeave,
    onDrop,
  };
  let containerText = (
    <React.Fragment>
      <i className="bi bi-file-earmark-spreadsheet"></i>
      <h4>
        <strong>Choose a file</strong> or drag it here
      </h4>
    </React.Fragment>
  );

  if (isProcessing) {
    containerText = <LoadingSpinner text="Processing file" />;
  } else if (csvFile) {
    containerText = (
      <h4>
        <strong>Filename: </strong>
        {csvFile.name}
      </h4>
    );
  }

  return (
    <Modal {...modalProps}>
      <div className="modal-content-wrapper">
        {props.instructions && <p>{props.instructions}</p>}

        <button
          className="button button-link no-padding"
          onClick={onDownloadTemplateClick}
        >
          Download CSV template
        </button>

        <hr />

        <div {...dragAndDropProps}>
          {containerText}
          <input type="file" ref={fileInput} onChange={onChange} />
        </div>
      </div>
    </Modal>
  );
}

BulkUploadModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

BulkUploadModal.defaultProps = {
  instructions: '',
};

const mapDispatchToProps = (dispatch) => ({
  addNewNotification: (message, category) =>
    dispatch(addNewNotification(message, category)),
});

export default connect(null, mapDispatchToProps)(BulkUploadModal);
