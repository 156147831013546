import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import classname from 'classname';

import Channels from 'components/Channels';
import EnvironmentSwitch from 'components/EnvironmentSwitch';
import TestCase from 'components/TestCase';
import LoadingSpinner from 'components/LoadingSpinner';
import NotificationField from 'components/NotificationField';
import TotalSelectedBadge from 'components/TotalSelectedBadge';
import JiraFields from 'components/JiraFields';

import Checkbox from 'components/Checkbox';
import Modal from 'components/Modal';
import MetafieldsModal from 'components/MetafieldsModal';
import BulkUploadModal from 'components/BulkUploadModal';
import ConfirmationModal from 'components/ConfirmationModal';

import {
  submitTestCases,
  removeHostingProject,
  loadGlobalFields,
} from 'store/actions/hosting_actions';
import { addNewNotification } from 'store/actions/notification_actions';
import filterList from 'selectors/search';
import {
  getHostingProjects,
  getHostingProjectTestCases,
  postHostingNewProject,
  putHostingEditProject,
  postHostingNewTestCase,
  putHostingEditTestCase,
  deleteHostingTestCase,
  postHostingCreateTestDataFile,
  deleteHostingTestDataFile,
  postHostingCreateExecutionTestDataFile,
  postHostingCreateTestData,
  getGlobalFields,
} from 'utils/api.service';
import { setDefaultFilter } from 'store/actions/jobsQueue_actions';
import HostingControls from './HostingControls';
import MoveTestCasesModal from './MoveTestCasesModal';
import GlobalFieldsModal from './GlobalFieldsModal';

class Hosting extends React.Component {
  state = {
    projects: [],
    activeProject: {},
    projectMetafields: [],
    testCases: [],
    testCaseMetafields: [],
    searchValue: '',
    showModal: false,
    modal: '',
    modalElement: null,
    deleteTestCaseInProgress: false,
    executionname: '',
    notificationEmail: '',
    selectAll: false,
    viewType: 'grid',
    isLoading: false,
    targetProject: '',
  };

  constructor() {
    super();

    this.onProjectSelected = this.onProjectSelected.bind(this);
    this.onTestCaseSelected = this.onTestCaseSelected.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onCommonFieldChange = this.onCommonFieldChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.submitNewProject = this.submitNewProject.bind(this);
    this.submitEditProject = this.submitEditProject.bind(this);
    this.submitUploadTestCases = this.submitUploadTestCases.bind(this);
    this.submitNewTestCase = this.submitNewTestCase.bind(this);
    this.submitEditTestCase = this.submitEditTestCase.bind(this);
    this.submitAddTestData = this.submitAddTestData.bind(this);
    this.submitTestCases = this.submitTestCases.bind(this);
    this.onNotificationEmailChange = this.onNotificationEmailChange.bind(this);
    this.onSelectAllClicked = this.onSelectAllClicked.bind(this);
    this.onViewChangeClicked = this.onViewChangeClicked.bind(this);
    this.setTargetProject = this.setTargetProject.bind(this);
    this.moveTestCasesBulk = this.moveTestCasesBulk.bind(this);
    this.editTestCasesBulk = this.editTestCasesBulk.bind(this);
    this.deleteTestCasesBulk = this.deleteTestCasesBulk.bind(this);
  }

  componentDidMount() {
    Promise.all([
      this.loadProjects(),
      this.fetchGlobalFields(this.props.org, this.props.workstream),
    ]);
  }

  componentDidUpdate(props) {
    if (props.workstreamName !== this.props.workstreamName) {
      Promise.all([
        this.loadProjects(),
        this.fetchGlobalFields(this.props.org, this.props.workstream),
      ]);
    }
  }

  // data loading
  loadProjects() {
    const { props } = this;

    this.setState(
      {
        projects: [],
        projectMetafields: [],
        activeProject: {},
        testCases: [],
        testCaseMetafields: [],
      },
      () => {
        getHostingProjects(props.org, props.workstream).then((response) => {
          let activeProject = {};

          if (response.data.project.length) {
            activeProject = response.data.project[0];
          }

          this.setState({
            projects: response.data.project,
            projectMetafields: response.data.metafield,
            activeProject,
            targetProject: activeProject.projectname,
          });

          if (activeProject.projectname) {
            this.setState({
              projectMetafields: response.data.metafield,
              activeProject,
              notificationEmail: props.userInfo.emailid,
            });

            this.loadTestCases(
              props.org,
              props.workstream,
              activeProject.projectname
            );
          }
        });
      }
    );
  }

  loadProject(org, workstream, projectname) {
    const activeProject = this.state.projects.find(
      (p) => p.projectname === projectname
    );

    this.setState(
      {
        activeProject,
        targetProject: activeProject.projectname,
      },
      () => {
        this.loadTestCases(org, workstream, projectname);
      }
    );
  }

  loadTestCases(org, workstream, project) {
    this.setState(
      {
        testCases: [],
        testCaseMetafields: [],
        loadingTestCases: true,
      },
      () => {
        getHostingProjectTestCases(org, workstream, project).then(
          (response) => {
            let testCases = response.data.results.map((t) => {
              t.testdata = t.testdata || {};

              let envObjExist = this.state.activeProject.envlist.some(
                (env) =>
                  t.testdata.hasOwnProperty(env.displayname.toUpperCase()) &&
                  typeof t.testdata[env.displayname.toUpperCase()] === 'object'
              );

              if (Object.keys(t.testdata).length > 0 && !envObjExist) {
                let oldTestData = t.testdata;
                t.testdata = {};
                this.state.activeProject.envlist.forEach(
                  (e) => (t.testdata[e.displayname.toUpperCase()] = oldTestData)
                );
              } else if (Object.keys(t.testdata).length === 0) {
                this.state.activeProject.envlist.forEach(
                  (e) => (t.testdata[e.displayname.toUpperCase()] = {})
                );
              }

              return { ...t, checked: false };
            });
            this.setState({
              testCases: testCases,
              testCaseMetafields: response.data.metafield,
              loadingTestCases: false,
            });
          }
        );
      }
    );
  }

  fetchGlobalFields(org, workstream) {
    getGlobalFields(org, workstream).then((response) => {
      this.props.loadGlobalFields(response.data);
    });
  }

  // user actions
  onProjectSelected(projectname) {
    const activeProject = this.state.projects.find(
      (p) => p.projectname === projectname
    );

    this.setState({
      activeProject,
      testCases: [],
      testCaseMetafields: [],
    });

    this.loadProject(this.props.org, this.props.workstream, projectname);
  }

  onTestCaseSelected(testCaseID) {
    const { testCases, selectAll } = this.state;
    const testCaseIndex = testCases.findIndex((t) => t.id === testCaseID);

    testCases[testCaseIndex].checked = !testCases[testCaseIndex].checked;

    if (selectAll && !testCases[testCaseIndex].checked) {
      this.setState({ selectAll: false });
    }

    this.setState({ testCases });
  }

  onSearchInputChange(e) {
    this.setState({
      searchValue: e.target.value,
    });
  }

  onCommonFieldChange({ target }) {
    this.setState({ [target.name]: target.value });
  }

  onNotificationEmailChange(value) {
    this.setState({ notificationEmail: value });
  }

  onSelectAllClicked(e) {
    const { checked } = e.target;
    const { testCases } = this.state;
    const filteredTests = filterList(
      testCases,
      this.state.searchValue,
      'displayname'
    )
      .filter((test) => {
        return test.status[this.props.activeEnvironment] !== 'disabled';
      })
      .map((test) => test.id);

    testCases.forEach((test) => {
      if (filteredTests.includes(test.id)) {
        test.checked = checked;
      }
    });

    this.setState({ testCases, selectAll: checked });
  }

  onViewChangeClicked(e) {
    this.setState({ viewType: e.target.dataset.view });
  }

  // misc
  toggleModal(modal, element) {
    return () => {
      this.setState({
        showModal: !!modal,
        modalElement: element,
        modal,
      });
    };
  }

  closeModal() {
    this.setState({ showModal: false, modal: '' });
  }

  setTargetProject(event) {
    this.setState({ targetProject: event.target.value });
  }

  uploadFilesToS3AndUpdatePayload(payload, files, fileType, endpoint) {
    const { props, state } = this;
    const fileUploadRequests = [];
    const testCaseIDMap = new Map();
    const filesToRemoveFromS3 = [];
    const envListArr = Object.values(state.activeProject.envlist).map(
      (e) => e.displayname
    );

    // prepare requests to upload files to S3
    Object.entries(files).forEach(([key, envFiles]) => {
      const { workstream } = props;
      const project = state.activeProject.projectname;
      const testcase = payload[key].testcasename;

      testCaseIDMap.set(testcase, key);

      envListArr.forEach((env) => {
        if (envFiles[env]) {
          fileUploadRequests.push(
            endpoint(
              workstream,
              project,
              testcase,
              envFiles[env],
              env,
              fileType
            )
          );
        }
      });
    });

    Object.entries(payload).forEach(([testcaseid, testcase]) => {
      const currentTestCase = state.testCases.find(
        (tc) => tc.id === testcaseid
      );

      // skip cloned (undefined) testcases created by replicating testdata
      if (!currentTestCase) {
        return;
      }

      envListArr.forEach((env) => {
        if (
          currentTestCase.testdata[env] &&
          currentTestCase.testdata[env].testdatafile
        ) {
          if (testcase.testdata[env] && !testcase.testdata[env].testdatafile) {
            filesToRemoveFromS3.push(
              currentTestCase.testdata[env].testdatafile
            );
          }
        }
        if (
          currentTestCase.testdata[env] &&
          currentTestCase.testdata[env].testiterationfile
        ) {
          if (
            testcase.testdata[env] &&
            !testcase.testdata[env].testiterationfile
          ) {
            filesToRemoveFromS3.push(
              currentTestCase.testdata[env].testiterationfile
            );
          }
        }
      });
    });

    // Upload test data files to S3
    return Promise.all(fileUploadRequests).then((responses) => {
      // update payload with returned URIs
      responses.forEach((response) => {
        const fileLocation = response.data.file_location;
        const testCaseName = response.data.testcasename;
        const environment = response.data.environment;
        const key = testCaseIDMap.get(testCaseName);

        if (payload[key].testdata[environment]) {
          payload[key].testdata[environment][fileType] = fileLocation;
        }
      });

      return { payload, filesToRemoveFromS3 };
    });
  }

  submitTestCases(payload, files, fileType) {
    return this.uploadFilesToS3AndUpdatePayload(
      payload,
      files,
      fileType,
      postHostingCreateExecutionTestDataFile
    ).then(({ payload }) => {
      Object.keys(payload).forEach((key) => {
        payload[key].testdata =
          payload[key].testdata[this.props.activeEnvironment];
      });
      this.props.submitTestCases(this.state.activeProject.projectname, {
        env: this.props.activeEnvironment,
        executionname: this.state.executionname,
        notificationemail: this.state.notificationEmail
          .split(',')
          .map((email) => email.trim())
          .join(','),
        jiraTestCycleId: this.state.jiraTestCycleId,
        testcases: Object.values(payload),
        workstreamname: this.props.workstream,
      });

      this.props.setDefaultFilter(this.props.org);
      this.props.history.push('/submit?from=tests&f=true');
    });
  }

  submitNewProject(payload) {
    return postHostingNewProject({
      org: this.props.org,
      workstreamname: this.props.workstream,
      ...payload.id,
    }).then((response) => {
      this.loadProjects();

      this.props.addNewNotification(response.data.message, 'success');
      this.closeModal();

      return response;
    });
  }

  submitEditProject(payload) {
    const { activeProject } = this.state;

    return putHostingEditProject(activeProject.projectname, {
      org: this.props.org,
      workstreamname: this.props.workstream,
      projectname: activeProject.projectname,
      ...payload[activeProject.id],
    }).then((response) => {
      this.loadProjects();

      this.props.addNewNotification(response.data.message, 'success');
      this.closeModal();

      return response;
    });
  }

  submitDeleteProject(project) {
    return () => {
      this.setState({ deleteProjectInProgress: true });

      return this.props
        .removeHostingProject(this.props.workstream, project.projectname)
        .then((response) => {
          let aa = response;

          // if error object
          if (response.response) {
            aa = response.response;
          }

          if (aa.status >= 200 && aa.status < 300) {
            this.loadProjects();
            this.closeModal();
          }
        })
        .finally(() => {
          this.setState({ deleteProjectInProgress: false });
        });
    };
  }

  submitUploadTestCases(payload) {
    const chunkSize = 50;
    const testcases = payload.slice(0, chunkSize);

    testcases.forEach((testcase) => {
      if (
        testcase.notsupportedenv &&
        typeof testcase.notsupportedenv === 'string'
      ) {
        testcase.notsupportedenv = testcase.notsupportedenv
          .replaceAll(' ', '')
          .split('|');
      } else {
        testcase.notsupportedenv = [];
      }
    });

    return postHostingNewTestCase({
      workstreamname: this.props.workstream,
      projectname: this.state.activeProject.projectname,
      testcases,
    }).then((response) => {
      this.loadTestCases(
        this.props.org,
        this.props.workstream,
        this.state.activeProject.projectname
      );

      this.props.addNewNotification(response.data.message, 'success');
      this.closeModal();

      return response;
    });
  }

  submitNewTestCase(payload) {
    const testcases = Object.keys(payload).map((key) => {
      return {
        ...payload[key],
        notsupportedenv: payload[key].notsupportedenv || [],
      };
    });

    return postHostingNewTestCase({
      workstreamname: this.props.workstream,
      projectname: this.state.targetProject,
      testcases,
    }).then((response) => {
      this.loadTestCases(
        this.props.org,
        this.props.workstream,
        this.state.activeProject.projectname
      );

      this.props.addNewNotification(response.data.message, 'success');
      this.closeModal();

      return response;
    });
  }

  submitEditTestCase(payload) {
    const selectedTestCase = this.state.modalElement;

    return putHostingEditTestCase(
      this.props.workstream,
      this.state.activeProject.projectname,
      selectedTestCase.testcasename,
      {
        projectname: this.state.activeProject.projectname,
        ...payload[selectedTestCase.id],
      }
    ).then((response) => {
      this.loadTestCases(
        this.props.org,
        this.props.workstream,
        this.state.activeProject.projectname
      );

      this.props.addNewNotification(response.data.message, 'success');
      this.closeModal();

      return response;
    });
  }

  async editTestCasesBulk(testCases) {
    const projectname = this.state.activeProject.projectname;
    const testCasesPayload = Object.keys(testCases).map((key) => {
      return {
        ...testCases[key],
        projectname,
      };
    });
    await this.submitBulkEditTestCases(testCasesPayload);
  }

  async moveTestCasesBulk(targetProject) {
    const projectname = this.state.activeProject.projectname;
    const testCasesPayload = this.state.testCases
      .filter((t) => t.checked)
      .map((t) => {
        return {
          ...t,
          projectname,
          toproject: targetProject,
        };
      });
    await this.submitBulkEditTestCases(testCasesPayload);
  }

  async submitBulkEditTestCases(testCasesPayload) {
    const editTestCasesPromises = testCasesPayload.map((testCase) => {
      return putHostingEditTestCase(
        this.props.workstream,
        this.state.activeProject.projectname,
        testCase.testcasename,
        testCase
      );
    });
    this.setState({ isLoading: true });
    try {
      const responses = await Promise.all(editTestCasesPromises);
      this.loadTestCases(
        this.props.org,
        this.props.workstream,
        this.state.activeProject.projectname
      );
      this.props.addNewNotification(
        'Test case(s) updated successfully',
        'success'
      );
      this.closeModal();
      return responses;
    } catch (error) {
      this.props.addNewNotification(error.response.data.detail, 'danger');
    } finally {
      this.setState({ isLoading: false });
    }
  }

  submitDeleteTestCase(testcase) {
    return () => {
      this.setState({ deleteTestCaseInProgress: true });

      deleteHostingTestCase(
        this.props.workstream,
        this.state.activeProject.projectname,
        testcase
      ).then((response) => {
        const testCases = Array.from(this.state.testCases);
        const currentTestCaseIndex = this.state.testCases.findIndex(
          (t) => t.testcasename === testcase
        );
        const currentTestCase = this.state.testCases[currentTestCaseIndex];

        testCases.splice(currentTestCaseIndex, 1);

        if (currentTestCase.testdata.testdatafile) {
          deleteHostingTestDataFile(currentTestCase.testdata.testdatafile);
        }

        this.setState({ testCases, deleteTestCaseInProgress: false });
        this.props.addNewNotification(response.data.message, 'success');

        this.closeModal();
      });
    };
  }

  deleteTestCasesBulk() {
    const testCases = this.state.testCases.filter((t) => t.checked);
    // call deleteHostingTestCase api for each test case in an array of promisses
    const deleteTestCasesPromises = testCases.map(({ testcasename }) => {
      return deleteHostingTestCase(
        this.props.workstream,
        this.state.activeProject.projectname,
        testcasename
      );
    });
    this.setState({ deleteTestCaseInProgress: true });
    Promise.all(deleteTestCasesPromises)
      .then((responses) => {
        this.setState({
          testCases: this.state.testCases.filter((t) => !t.checked),
        });
        this.props.addNewNotification(
          'Test case(s) deleted successfully',
          'success'
        );
        // call deleteHostingTestDataFile api
        // for those test cases which have testdata.testdatafile
        const testCasesWithTestDataFile = testCases.filter((testCase) =>
          Boolean(testCase.testdata.testdatafile)
        );
        const deleteTestDataFilePromises = testCasesWithTestDataFile.map(
          (testCase) => {
            return deleteHostingTestDataFile(testCase.testdata.testdatafile);
          }
        );
        Promise.all(deleteTestDataFilePromises)
          .then((_) => {})
          .catch((error) => {
            this.props.addNewNotification(error.response.data.detail, 'danger');
          });
        return responses;
      })
      .catch((error) => {
        this.props.addNewNotification(error.response.data.detail, 'danger');
      })
      .finally(() => {
        this.setState({ deleteTestCaseInProgress: false });
        this.closeModal();
      });
  }

  submitAddTestData(payload, files, fileType) {
    const { props, state } = this;

    return this.uploadFilesToS3AndUpdatePayload(
      payload,
      files,
      fileType,
      postHostingCreateTestDataFile
    ).then(({ payload, filesToRemoveFromS3 }) => {
      // submit the payload to update/create test data
      return postHostingCreateTestData({
        projectname: state.activeProject.projectname,
        workstreamname: props.workstream,
        testcases: Object.values(payload),
      }).then((response) => {
        this.loadTestCases(
          props.org,
          props.workstream,
          state.activeProject.projectname
        );

        if (Object.entries(files).length === 0 && filesToRemoveFromS3.length) {
          if (filesToRemoveFromS3.length) {
            filesToRemoveFromS3.forEach((fileURI) => {
              deleteHostingTestDataFile(fileURI);
            });
          }
        }

        this.closeModal();

        return response;
      });
    });
  }

  // render methods
  renderProjects(projects) {
    if (this.state.projectMetafields.length) {
      const channels = projects.map((p) => ({
        subcategoryname: p.displayname,
        subcategoryid: p.projectname,
        status: p.status,
      }));
      const channelsProps = {
        channels,
        onClick: this.onProjectSelected,
        selectedChannel: this.state.activeProject.projectname,
        actions: [],
      };

      if (this.props.role === 'admin') {
        channelsProps.actions.push(
          {
            name: 'create-project',
            text: 'Add project',
            onClick: this.toggleModal('createProject'),
          },
          {
            name: 'global-fields',
            text: 'Global fields',
            onClick: this.toggleModal('globalFields'),
          }
        );

        channelsProps.activeChannelSecondaryAction = {
          text: <i className="project-icon bi bi-pencil-fill"></i>,
          onClick: this.toggleModal('editProject'),
        };
      }

      return <Channels {...channelsProps} />;
    }

    return <LoadingSpinner text="Loading projects" />;
  }

  renderFiltersAndButtons() {
    if (this.state.testCaseMetafields.length) {
      const searchProps = {
        type: 'search',
        onChange: this.onSearchInputChange,
        value: this.state.searchValue,
        name: 'search',
        className: 'form-control',
        placeholder: 'Search test cases',
      };
      const filteredTests = filterList(
        this.state.testCases,
        this.state.searchValue,
        'displayname'
      ).filter((test) => {
        return test.status[this.props.activeEnvironment] !== 'disabled';
      });
      const selectedTestCases = this.state.testCases.filter((s) => s.checked);
      const adminButtons = [];
      const actionButtons = [];

      if (this.props.role === 'admin') {
        // CREATE or UPLOAD new test cases
        if (this.state.testCaseMetafields.length) {
          adminButtons.push(
            <button
              key="newtestcase"
              className="btn btn-link mr-3"
              onClick={this.toggleModal('newTestCase')}
            >
              Add tests
            </button>
          );

          // CREATE test data
          adminButtons.push(
            <button
              key="addtestdata"
              className="btn btn-primary"
              disabled={!selectedTestCases.length}
              onClick={this.toggleModal('addTestData')}
            >
              Manage test data
            </button>
          );
        }
      }

      actionButtons.push(
        <button
          key="submit"
          type="button"
          onClick={this.toggleModal('submitTestCases')}
          disabled={!selectedTestCases.length}
          className="btn btn-primary"
        >
          Submit tests
        </button>
      );

      return (
        <div className="hosting-buttons">
          <div className="form-group col-md-3 pl-0">
            <input {...searchProps} />
          </div>

          <div className="col pl-0">
            <div
              className="btn-group btn-group-xs view-type ml-4 mr-4"
              role="group"
            >
              <button
                type="button"
                className={classname({
                  btn: true,
                  'btn-default': true,
                  selected: this.state.viewType === 'grid',
                })}
                data-view="grid"
                onClick={this.onViewChangeClicked}
              >
                <i className="bi bi-grid-3x3-gap-fill"></i>
              </button>
              <button
                type="button"
                className={classname({
                  btn: true,
                  'btn-default': true,
                  selected: this.state.viewType === 'table',
                })}
                data-view="table"
                onClick={this.onViewChangeClicked}
              >
                <i className="bi bi-table"></i>
              </button>
            </div>

            {adminButtons}
          </div>

          <div className="col-md-5 p-0 text-right">
            <TotalSelectedBadge
              available={filteredTests.length}
              selected={selectedTestCases.length}
              total={this.state.testCases.length}
            />

            <div className="hosting-select-all">
              <Checkbox
                onChange={this.onSelectAllClicked}
                checked={this.state.selectAll}
              />{' '}
              Select all
            </div>

            {actionButtons}
          </div>
        </div>
      );
    }

    return null;
  }

  renderTestCases(testCases) {
    const { state } = this;

    if (state.loadingTestCases && !testCases.length) {
      return <LoadingSpinner text="Loading test cases" />;
    } else {
      // if table view is selected
      const filteredTests = filterList(
        testCases,
        state.searchValue,
        'displayname'
      ).filter((test) => {
        return test.status[this.props.activeEnvironment] !== 'disabled';
      });

      if (state.viewType === 'table') {
        const columns = [
          {
            width: 40,
            Cell: ({ original }) => {
              const onChangeFn = original.disabled
                ? () => {}
                : () => this.onTestCaseSelected(original.id);

              return (
                <div className="w-50 d-flex justify-content-center align-items-center">
                  <Checkbox checked={original.checked} onChange={onChangeFn} />
                </div>
              );
            },
          },
          {
            Header: 'Name',
            accessor: 'displayname',
            resizable: true,
            Cell: ({ original }) => {
              const onChangeFn = original.disabled
                ? () => {}
                : this.toggleModal('showTestCase', original);

              return (
                <button
                  className="btn btn-link"
                  title={original.displayname}
                  onClick={onChangeFn}
                >
                  {original.displayname}
                </button>
              );
            },
          },
          {
            Header: 'Description',
            accessor: 'description',
            Cell: ({ original }) => {
              const textLength = 80;
              const ellipsis =
                original.description.length > textLength ? '...' : '';

              return (
                <span
                  title={original.description}
                >{`${original.description.substring(
                  0,
                  textLength
                )}${ellipsis}`}</span>
              );
            },
          },
          {
            Header: 'Last Modified',
            width: 180,
            Cell: ({ original }) => (
              <span className="w-100 text-center">
                {new Date(original.modifieddate).toLocaleString()}
              </span>
            ),
          },
        ];
        const tableProps = {
          columns,
          data: filteredTests,
          pageSize: filteredTests.length,
          resizable: false,
          showPagination: false,
          sortable: true,
        };

        if (this.props.role === 'admin') {
          columns.push({
            Header: 'Actions',
            width: 185,
            Cell: ({ original }) => {
              return (
                <>
                  <button
                    className="btn btn-link"
                    onClick={this.toggleModal('editTestCase', original)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={this.toggleModal(
                      'deleteTestCaseConfirmation',
                      original.testcasename
                    )}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={this.toggleModal('cloneTestCase', original)}
                  >
                    Clone
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={this.toggleModal('moveTestCase', original)}
                  >
                    Move
                  </button>
                </>
              );
            },
          });
        }

        return <ReactTable {...tableProps} />;
      } else {
        const tests = filterList(
          testCases,
          state.searchValue,
          'displayname'
        ).map((testCase) => {
          if (testCase.status[this.props.activeEnvironment] === 'disabled') {
            return null;
          } else {
            const testCaseProps = {
              checked: testCase.checked,
              description: testCase.description,
              name: testCase.displayname,
              testcaseid: testCase.id,
              onItemSelected: this.onTestCaseSelected,
              onLabelClick: this.toggleModal('showTestCase', testCase),
              learnMoreUrl: testCase.wiki,
            };

            if (this.props.role === 'admin') {
              testCaseProps.secondaryActions = [
                {
                  name: 'edit-test-case',
                  text: 'Edit',
                  onClick: this.toggleModal('editTestCase', testCase),
                },
                {
                  name: 'delete-test-case',
                  text: 'Delete',
                  onClick: this.toggleModal(
                    'deleteTestCaseConfirmation',
                    testCase.testcasename
                  ),
                },
                {
                  name: 'clone-test-case',
                  text: 'Clone',
                  onClick: this.toggleModal('cloneTestCase', testCase),
                },
                {
                  name: 'move-test-case',
                  text: 'Move',
                  onClick: this.toggleModal('moveTestCase', testCase),
                },
              ];
            }

            return (
              <div
                className="scenario"
                key={testCase.testcasename.split(' ').join('-')}
              >
                <TestCase {...testCaseProps} key={testCase.id} />
              </div>
            );
          }
        });
        const emptyItems = [
          ...Array(
            Math.ceil(tests.filter((t) => t).length / 3) * 3 -
              tests.filter((t) => t).length
          ).keys(),
        ];
        const placeholders = emptyItems.map((idx) => (
          <div className="scenario empty" key={`empty-${idx}`}></div>
        ));

        return [...tests, ...placeholders];
      }
    }
  }

  renderModal() {
    const { props, state } = this;
    let selectedTestCases = JSON.stringify(
      state.testCases.filter((t) => t.checked)
    );

    selectedTestCases = JSON.parse(selectedTestCases);

    if (state.showModal) {
      let modalProps = {
        environment: props.activeEnvironment,
        onClose: this.closeModal,
      };

      switch (state.modal) {
        case 'createProject':
          modalProps = {
            ...modalProps,
            elements: [{ id: 'id' }],
            metafields: this.state.projectMetafields,
            onSubmit: this.submitNewProject,
            title: 'Create new project',
          };

          break;
        case 'globalFields':
          return (
            <GlobalFieldsModal
              onClose={this.closeModal}
              org={this.props.org}
              workstream={this.props.workstream}
            />
          );
        case 'editProject':
          modalProps = {
            ...modalProps,
            elements: [this.state.activeProject],
            metafields: this.state.projectMetafields,
            valueFromElements: true,
            onSubmit: this.submitEditProject,
            title: 'Edit project',
            readOnlyFields: ['projectname'],
            children: (
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-danger"
                  onClick={this.toggleModal(
                    'deleteProject',
                    this.state.activeProject
                  )}
                >
                  Delete project
                </button>
              </div>
            ),
          };

          break;
        case 'deleteProject':
          return (
            <ConfirmationModal
              message={`Do you want to delete the project ${this.state.modalElement.displayname}? This action cannot be undone.`}
              onClose={this.closeModal}
              onConfirm={this.submitDeleteProject(this.state.modalElement)}
              loading={this.state.deleteProjectInProgress}
            />
          );
        case 'showTestCase':
          modalProps = {
            ...modalProps,
            elements: [this.state.modalElement],
            metafields: this.state.testCaseMetafields,
            valueFromElements: true,
            modal: state.modal,
            title: this.state.modalElement.displayname,
            parent: this.state.activeProject,
            readOnlyFields: this.state.testCaseMetafields.map((f) => f.field),
            hiddenFields: ['notsupportedenv'],
          };

          break;
        case 'newTestCase':
          modalProps = {
            ...modalProps,
            title: 'Add or upload test cases',
          };

          return (
            <Modal {...modalProps}>
              <div className="new-test-case-modal">
                <button onClick={this.toggleModal('uploadTestCases')}>
                  <i className="bi bi-plus"></i>
                  <span className="button-title">Upload tests</span>
                  <span className="button-description">
                    Upload a CSV file to create multiple tests
                  </span>
                </button>

                <button onClick={this.toggleModal('createTestCase')}>
                  <i className="bi bi-folder"></i>
                  <span className="button-title">Create a test</span>
                  <span className="button-description">
                    Add test case information manually
                  </span>
                </button>
              </div>
            </Modal>
          );
        case 'uploadTestCases':
          modalProps = {
            ...modalProps,
            onSubmit: this.submitUploadTestCases,
            fields: this.state.testCaseMetafields
              .map((f) => f.field)
              .filter((f) => !['env', 'testdata'].includes(f)),
            instructions:
              'To upload multiple tests cases at a time please use the provided CSV template. At the moment Testing Hub only supports CSV files with up to 50 test cases. Additional test cases will be ignored.',
          };

          return <BulkUploadModal {...modalProps} />;
        case 'createTestCase':
          modalProps = {
            ...modalProps,
            elements: [{ id: 'id' }],
            metafields: this.state.testCaseMetafields,
            onSubmit: this.submitNewTestCase,
            hiddenFields: ['env', 'testdata'],
            title: 'Create new test case',
            parent: this.state.activeProject,
          };

          if (this.state.activeProject.tools === 'maven') {
            modalProps.hiddenFields.push('testcasename');
          }

          break;
        case 'cloneTestCase':
          modalProps = {
            ...modalProps,
            elements: [{ ...this.state.modalElement, id: 'id' }],
            metafields: this.state.testCaseMetafields,
            onSubmit: this.submitNewTestCase,
            valueFromElements: true,
            hiddenFields: ['env', 'testdata', 'testcasename'],
            readOnlyFields: [],
            title: 'Clone test case',
            parent: this.state.activeProject,
          };

          break;
        case 'cloneTestCasesBulk':
          modalProps = {
            ...modalProps,
            elements: state.testCases.filter((t) => t.checked),
            metafields: [
              {
                name: 'Target project',
                field: 'toproject',
                type: 'select',
                default: this.state.activeProject.projectname,
                options: this.state.projects.map((project) => ({
                  id: project.projectname,
                  name: project.displayname,
                })),
              },
              ...this.state.testCaseMetafields,
            ],
            onSubmit: this.submitNewTestCase,
            valueFromElements: true,
            hiddenFields: ['env', 'testdata', 'testcasename', 'toproject'],
            readOnlyFields: [],
            title: 'Clone Test Cases in Bulk',
            parent: this.state.activeProject,
            onChangeProject: this.setTargetProject,
          };

          break;
        case 'moveTestCase':
          modalProps = {
            ...modalProps,
            elements: [{ ...this.state.modalElement }],
            metafields: [
              {
                name: 'Target project',
                field: 'toproject',
                type: 'select',
                default: this.state.activeProject.projectname,
                options: this.state.projects.map((project) => ({
                  id: project.projectname,
                  name: project.displayname,
                })),
              },
              ...this.state.testCaseMetafields,
            ],
            valueFromElements: true,
            hiddenFields: this.state.testCaseMetafields.map(
              (field) => field.field
            ),
            onSubmit: this.submitEditTestCase,
            title: 'Move test case',
            parent: this.state.activeProject,
          };

          break;

        case 'moveTestCasesBulk':
          return (
            <MoveTestCasesModal
              projects={this.state.projects.map((project) => ({
                id: project.projectname,
                displayname: project.displayname,
              }))}
              onClose={this.closeModal}
              onSubmit={this.moveTestCasesBulk}
              sourceProject={this.state.activeProject.projectname}
              loading={this.state.isLoading}
              selectedTestCases={state.testCases
                .filter((t) => t.checked)
                .map((t) => t.displayname)}
            />
          );

        case 'editTestCase':
          modalProps = {
            ...modalProps,
            elements: [this.state.modalElement],
            metafields: this.state.testCaseMetafields,
            onSubmit: this.submitEditTestCase,
            valueFromElements: true,
            hiddenFields: ['env', 'testdata'],
            readOnlyFields: ['testcasename'],
            title: 'Edit test case',
            parent: this.state.activeProject,
          };

          break;

        case 'editTestCasesBulk':
          modalProps = {
            ...modalProps,
            elements: state.testCases.filter((t) => t.checked),
            metafields: this.state.testCaseMetafields,
            onSubmit: this.editTestCasesBulk,
            valueFromElements: true,
            hiddenFields: ['env', 'testdata'],
            readOnlyFields: ['testcasename'],
            title: 'Edit Test Cases in Bulk',
            parent: this.state.activeProject,
          };

          break;
        case 'addTestData':
          modalProps = {
            ...modalProps,
            elements: selectedTestCases,
            metafields: this.state.testCaseMetafields,
            onSubmit: this.submitAddTestData,
            valueFromElements: true,
            parent: this.state.activeProject,
            modal: state.modal,
            showFieldMandatoryLabelHeader: false,
            hiddenFields: [
              'displayname',
              'testcasename',
              'env',
              'description',
              'testcasedir',
              'parameters',
              'wiki',
              'tools',
              'os',
              'notsupportedenv',
              'testClass',
              'testGroup',
              'testMethod',
              'spec',
              'grep',
              'grepInvert',
              'project'
            ],
            readOnlyFields: ['env'],
            title: 'Add test data',
          };

          break;
        case 'submitTestCases':
          const metafields = this.state.testCaseMetafields;

          metafields.find((m) => m.field === 'env').default =
            this.props.activeEnvironment;
          modalProps = {
            ...modalProps,
            elements: selectedTestCases,
            metafields: metafields,
            onSubmit: this.submitTestCases,
            valueFromElements: true,
            readOnlyFields: ['testcasedir'],
            hiddenFields: [
              'wiki',
              'notsupportedenv',
              'parameters',
              'env',
              'testcasename',
              'displayname',
              'description',
              'tools',
              'os',
              'testClass',
              'testGroup',
              'testMethod',
              'spec',
              'grep',
              'grepInvert',
              'project'
            ],
            title: 'Submit test case',
            parent: this.state.activeProject,
            commonFields: [
              <div className="col-sm-12 form-group">
                <div className="row">
                  <div className="col-4 form-group" key="executionname">
                    <label htmlFor="executionname">
                      Execution name <span className="optional">Optional</span>
                    </label>
                    <input
                      className="form-control"
                      name="executionname"
                      type="text"
                      onChange={this.onCommonFieldChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 form-group" key="notificationemail">
                    <NotificationField
                      value={state.notificationEmail}
                      notifyAllValue={state.activeProject.emaildl}
                      onChange={this.onNotificationEmailChange}
                      loggedInUserEmail={props.userInfo.emailid}
                    />
                  </div>

                  <div className="col-4">
                    <label htmlFor="jirafield">
                      Update JIRA <span className="optional">Optional</span>
                    </label>
                    <div className="row">
                      <JiraFields onChange={this.onCommonFieldChange} />
                    </div>
                  </div>
                </div>
              </div>,
            ],
          };

          break;
        case 'deleteTestCaseConfirmation':
          return (
            <ConfirmationModal
              message={
                'Do you want to delete the selected test case? This action cannot be undone.'
              }
              onClose={this.closeModal}
              onConfirm={this.submitDeleteTestCase(this.state.modalElement)}
              loading={this.state.deleteTestCaseInProgress}
            />
          );
        case 'deleteTestCasesBulk':
          return (
            <ConfirmationModal
              message={`Do you want to delete ${
                state.testCases.filter((t) => t.checked).length
              } selected test cases? This action cannot be undone.`}
              onClose={this.closeModal}
              onConfirm={this.deleteTestCasesBulk}
              loading={this.state.deleteTestCaseInProgress}
            >
              <div className="modal-form-common-fields">
                <hr />
                {state.testCases
                  .filter((t) => t.checked)
                  .map((t) => (
                    <div className="testcase-list-item">{t.displayname}</div>
                  ))}
              </div>
            </ConfirmationModal>
          );
        default:
          break;
      }

      return <MetafieldsModal {...modalProps} />;
    }

    return null;
  }

  render() {
    const { projectMetafields, loadingTestCases } = this.state;
    const projects = this.renderProjects(this.state.projects);
    const testCases = this.renderTestCases(this.state.testCases);
    const modal = this.renderModal();
    const environmentProps = {
      environments: this.state.activeProject.envlist,
      context: this.props.workstream,
    };
    const filteredTests = filterList(
      this.state.testCases,
      this.state.searchValue,
      'displayname'
    ).filter((test) => {
      return test.status[this.props.activeEnvironment] !== 'disabled';
    });

    const selectedTestCases = this.state.testCases.filter((s) => s.checked);

    return (
      <div className="hosting-wrapper">
        <section className="section-title">
          <div className="section-title-group">
            <h2 className="title">
              {this.props.workstreamName || 'Loading...'}
            </h2>
          </div>

          {this.state.activeProject.envlist &&
            this.state.activeProject.envlist.length > 0 && (
              <EnvironmentSwitch {...environmentProps} />
            )}
        </section>

        <div className="hosting-projects">{projects}</div>

        {!loadingTestCases && projectMetafields.length > 0 && (
          <HostingControls
            available={filteredTests.length}
            selected={selectedTestCases.length}
            total={this.state.testCases.length}
            onSelectAll={this.onSelectAllClicked}
            selectedAll={this.state.selectAll}
            showModalWithContent={this.toggleModal}
            onChangeView={this.onViewChangeClicked}
            viewType={this.state.viewType}
            onSearchInputChange={this.onSearchInputChange}
            searchValue={this.state.searchValue}
          />
        )}

        <div className={`scenarios ${this.state.viewType}`}>{testCases}</div>

        {modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { menu, environment, userInfo } = state.app;
  const { service } = menu;
  const { globalFields } = state.hosting;

  return {
    workstream: menu[service],
    workstreamName: menu.categoryName,
    org: service,
    activeEnvironment: environment[menu[service]],
    role: state.hosting.role,
    userInfo: userInfo,
    globalFields,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewNotification: (message, category) =>
    dispatch(addNewNotification(message, category)),
  submitTestCases: (project, payload) =>
    dispatch(submitTestCases(project, payload)),
  setDefaultFilter: (source) => dispatch(setDefaultFilter(source)),
  removeHostingProject: (workstream, project) =>
    dispatch(removeHostingProject(workstream, project)),
  loadGlobalFields: (fields) => dispatch(loadGlobalFields(fields)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hosting);
