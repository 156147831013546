import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

const TestDataManagement = ({ onAddTestData, elements }) => {
  const globalFields = useSelector((state) => state.hosting.globalFields);
  const [valueOptions, setValueOptions] = useState([]);

  const validationSchema = Yup.object().shape({
    key: Yup.string()
      .required('Key is required')
      .matches(/^[a-zA-Z0-9_-]+$/, 'Key cannot contain special characters')
      .test(
        'is-unique-key',
        'This key already exists in test data',
        function (value) {
          return !elements.hasOwnProperty(value);
        }
      ),
    value: Yup.string()
      .matches(
        /^[a-zA-Z0-9/@., _;:-]+$/,
        'Value cannot contain special characters'
      )
      // valiate the value is one of the options for the selected key
      .when('key', {
        is: (key) => keyOptions.some((opt) => opt.value === key),
        then: (schema) =>
          schema.test(
            'is-valid-option',
            'Invalid value. Select one from the dropdown',
            function (value) {
              return valueOptions.some((option) => option.value === value);
            }
          ),
      }),
  });

  const handleKeyChange = (selectedOption, setFieldValue) => {
    const selectedKey = selectedOption ? selectedOption.value : '';
    setFieldValue('key', selectedKey);
    setFieldValue('value', ''); // Clear the value field

    const field = globalFields.fields.find((f) => f.key === selectedKey);
    if (!field) {
      setValueOptions([]);
      return;
    }

    if (field.type === 'choices') {
      setValueOptions(
        field.options.map((opt) => ({ value: opt.value, label: opt.value }))
      );
      // set the default value for choices type fields
      setFieldValue(
        'value',
        field.options.find((opt) => opt.value === field.default).value
      );
    } else {
      setValueOptions([{ value: field.value, label: field.value }]);
      setFieldValue('value', field.value);
    }
  };

  const keyOptions = useMemo(() => {
    return globalFields.fields.map((field) => ({
      value: field.key,
      label: field.key,
    }));
  }, [globalFields]);

  return (
    <Formik
      initialValues={{ key: '', value: '' }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { resetForm }) => {
        onAddTestData(values.key, values.value);
        resetForm();
      }}
    >
      {({ setFieldValue, values, setErrors, errors }) => (
        <Form>
          <div className="inputs-container">
            <div className="col-sm-6 input-select-container">
              <Select
                id="key"
                name="key"
                placeholder="Key"
                options={keyOptions}
                onChange={(option) => {
                  handleKeyChange(option, setFieldValue);
                  setErrors({ keyt: '', value: '' });
                }}
                onInputChange={(inputValue, { action }) => {
                  if (
                    action === 'input-change' &&
                    !keyOptions.some((opt) => opt.value === inputValue)
                  ) {
                    setFieldValue('key', inputValue);
                    setFieldValue('value', ''); // Clear the value field
                    setValueOptions([]);
                    setErrors({ keyt: '', value: '' });
                  }
                }}
                onBlur={() => {
                  if (!keyOptions.some((opt) => opt.value === values.key)) {
                    setFieldValue('key', values.key);
                  }
                }}
                value={
                  values.key ? { value: values.key, label: values.key } : null
                }
                isClearable
                noOptionsMessage={() => null}
              />
              <ErrorMessage
                name="key"
                component="div"
                className="invalid-feedback d-block"
              />
            </div>
            <div className="col-sm-5">
              <Select
                id="value"
                name="value"
                placeholder="Value"
                options={valueOptions}
                onChange={(option) => {
                  setFieldValue('value', option ? option.value : '');
                  setErrors({ key: '', value: '' });
                }}
                onInputChange={(inputValue, { action }) => {
                  if (
                    action === 'input-change' &&
                    !valueOptions.some((opt) => opt.value === inputValue)
                  ) {
                    setFieldValue('value', inputValue);
                    setErrors({ key: '', value: '' });
                  }
                }}
                onBlur={() => {
                  if (!valueOptions.some((opt) => opt.value === values.value)) {
                    setFieldValue('value', values.value);
                  }
                }}
                value={
                  values.value
                    ? { value: values.value, label: values.value }
                    : null
                }
                noOptionsMessage={() => null}
              />
              <ErrorMessage
                name="value"
                component="div"
                className="invalid-feedback d-block"
              />
            </div>
            <button type="submit" className="btn btn-primary col-sm">
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TestDataManagement;
