import NetworkRequest from './NetworkRequest';

export const getNavigationData = () =>
  NetworkRequest.request('getNavigationData');

export const fetchCategoryDataQuarry = (id) =>
  NetworkRequest.request('fetchCategoryDataQuarry', {
    params: { id },
  });

export const fetchChannelList = (categoryid, channelid) =>
  NetworkRequest.request('fetchChannelList', {
    params: { categoryid, channelid },
    cacheable: false,
  });

export const getQuarryChannels = (category) =>
  NetworkRequest.request('getQuarryChannels', {
    params: { category },
  });

export const getQuarryTestCases = (category, channel, env) =>
  NetworkRequest.request('getQuarryTestCases', {
    params: { category, channel, env },
    cacheable: false,
  });

export const getProducts = (channelName, env) =>
  NetworkRequest.request('getProducts', {
    params: { channelName, env },
    cacheable: false,
  });
export const getPublicKey = async () => {
  const keyName = 'ency_public_key';
  if (!sessionStorage.getItem(keyName)) {
    const r = await NetworkRequest.request('getPublicKey', {
      cacheable: true,
    });
    sessionStorage.setItem(keyName, r.data.key);
  }
  return sessionStorage.getItem(keyName);
};

export const getQuarrySKUs = (type, fulfillment, region, channel, env) =>
  NetworkRequest.request('getQuarrySKUs', {
    params: { type, fulfillment, region, channel, env },
  });

export const getMyQuaryOrders = (limit) =>
  NetworkRequest.request('getMyQuarryOrders', {
    params: { limit },
    cacheable: false,
  });

export const getCustomOrders = (filter, limit) =>
  NetworkRequest.request('getCustomOrders', {
    params: { filter, limit },
    cacheable: false,
  });

export const submitOrderToQuarry = (payload, version, channel) =>
  NetworkRequest.request('submitOrderToQuarry', {
    params: { version, channel },
    data: payload,
  });

export const getQuarryTestCaseFields = (category, channel, metafield, env) =>
  NetworkRequest.request('getQuarryTestCaseFields', {
    params: { category, channel, metafield, env },
  });

export const getQuarryProductFields = (category, channel, materialcode, env) =>
  NetworkRequest.request('getQuarryProductFields', {
    params: { category, channel, materialcode, env },
  });

export const getMeshTestCaseFields = (channel, metafield) =>
  NetworkRequest.request('getMeshTestCaseFields', {
    params: { channel, metafield },
  });

export const getMyMeshTestCases = (limit) =>
  NetworkRequest.request('getMyMeshTestCases', {
    params: { limit },
    cacheable: false,
  });

export const getCustomTestCases = (filter, limit) => {
  let query = `limit=${limit}`;

  if (filter !== 'all') {
    query = `${query}&status=${filter}`;
  }

  return NetworkRequest.request('getCustomTestCases', {
    params: { query },
    cacheable: false,
  });
};

export const createTestCase = (payload, channel) =>
  NetworkRequest.request('createTestCase', {
    params: { channel },
    data: payload,
  });

export const createAccessTestCase = (payload, channel) =>
  NetworkRequest.request('createAccessTestCase', {
    params: { channel },
    data: payload,
  });

export const fetchNextDataSet = (url) =>
  NetworkRequest.request('fetchNextDataSet', {
    params: { url },
    cacheable: false,
  });

// Hosting
export const getHostingProjects = (org, workstream) =>
  NetworkRequest.request('getHostingProjects', {
    params: { org, workstream },
    cacheable: false,
  });

export const getHostingProject = (org, workstream, project) =>
  NetworkRequest.request('getHostingProject', {
    params: { org, workstream, project },
    cacheable: false,
  });

export const getHostingProjectTestCases = (org, workstream, project) =>
  NetworkRequest.request('getHostingProjectTestCases', {
    params: { org, workstream, project },
    cacheable: false,
  });

export const getHostingProjectTestCase = (org, workstream, project, testcase) =>
  NetworkRequest.request('getHostingProjectTestCase', {
    params: { org, workstream, project, testcase },
    cacheable: false,
  });

export const postHostingNewProject = (payload) =>
  NetworkRequest.request('postHostingNewProject', {
    params: {},
    data: payload,
  });

export const putHostingEditProject = (project, payload) =>
  NetworkRequest.request('putHostingEditProject', {
    params: { project },
    data: payload,
  });

export const deleteHostingProject = (workstream, project) =>
  NetworkRequest.request('deleteHostingProject', {
    params: { workstream, project },
  });

export const postHostingNewTestCase = (payload) =>
  NetworkRequest.request('postHostingNewTestCase', {
    params: {},
    data: payload,
  });

export const putHostingEditTestCase = (
  workstream,
  project,
  testcase,
  payload
) =>
  NetworkRequest.request('putHostingEditTestCase', {
    params: { workstream, project, testcase },
    data: payload,
  });

export const deleteHostingTestCase = (workstream, project, testcase) =>
  NetworkRequest.request('deleteHostingTestCase', {
    params: { workstream, project, testcase },
  });

export const postHostingCreateTestDataFile = (
  workstream,
  project,
  testcase,
  payload,
  env,
  filetype
) =>
  NetworkRequest.request(
    'postHostingCreateTestDataFile',
    {
      params: { workstream, project, testcase, env, filetype },
      data: payload,
    },
    {
      'Content-Type': payload.type,
    }
  );

export const postHostingCreateExecutionTestDataFile = (
  workstream,
  project,
  testcase,
  payload,
  env,
  filetype
) =>
  NetworkRequest.request(
    'postHostingCreateExecutionTestDataFile',
    {
      params: { workstream, project, testcase, env, filetype },
      data: payload,
    },
    {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
  );

export const postHostingCreateTestData = (payload) =>
  NetworkRequest.request('postHostingCreateTestData', {
    params: {},
    data: payload,
  });

export const deleteHostingTestDataFile = (uri) =>
  NetworkRequest.request('deleteHostingTestDataFile', {
    params: { uri },
  });

export const postHostingProjectTestCase = (project, payload) =>
  NetworkRequest.request('postHostingProjectTestCase', {
    params: { project },
    data: payload,
    cacheable: false,
  });

export const getGlobalFields = (org, workstream) =>
  NetworkRequest.request('getGlobalFields', {
    params: { org, workstream },
    cacheable: false,
  });

export const postGlobalFields = (org, workstream, payload) =>
  NetworkRequest.request('postGlobalFields', {
    params: { org, workstream },
    data: payload,
  });

// Jobs queue page
export const getHostingExecutions = (org, limit, offset, status) => {
  let query = '';

  if (offset) {
    query = `&offset=${offset}`;
  }

  if (status && status !== 'all') {
    query = `&status=${status}`;
  }

  return NetworkRequest.request('getHostingExecutions', {
    params: { org, limit, query },
    cacheable: false,
  });
};

export const getTestCaseStatus = (executionid, id, sharedby) => {
  let query = '';

  if (sharedby) {
    query = `sharedby=${sharedby}`;
  }

  return NetworkRequest.request('getTestCaseStatus', {
    params: { executionid, id, query },
    cacheable: false,
  });
};

export const getTestDataStatus = (executionid, id) =>
  NetworkRequest.request('getTestDataStatus', {
    params: { executionid, id },
    cacheable: false,
  });

export const getScreenshot = (screenshotname) =>
  NetworkRequest.request('getScreenshot', {
    params: { screenshotname },
    cacheable: false,
  });

export const getPlaywrightReport = (executionid, transactionid) =>
  transactionid
    ? NetworkRequest.request('getPlaywrightReportTransaction', {
        params: { executionid, transactionid },
        cacheable: false,
      })
    : NetworkRequest.request('getPlaywrightReport', {
        params: { executionid },
        cacheable: false,
      });

export const getHostingStatus = (executionid, id) =>
  NetworkRequest.request('getHostingStatus', {
    params: { executionid, id },
    cacheable: false,
  });

export const getHostingExecutionStatus = (service, executionid) =>
  NetworkRequest.request('getHostingExecutionStatus', {
    params: { service, executionid },
    cacheable: false,
  });

export const postHostingAbortTransactions = (executionid, payload) =>
  NetworkRequest.request('postHostingAbortTransactions', {
    params: { executionid },
    data: payload,
  });

export const postPublishReports = (service, payload) =>
  NetworkRequest.request('postPublishReports', {
    params: { service },
    data: payload,
  });

export const postPublishToJIRA = (service, payload) =>
  NetworkRequest.request('postPublishToJIRA', {
    params: { service },
    data: payload,
  });

export const deleteExecutionTransactions = (service, executionid, payload) =>
  NetworkRequest.request('deleteExecutionTransactions', {
    params: { service, executionid },
    data: payload,
  });

// Test Suites
export const getTestSuites = (service) =>
  NetworkRequest.request('getTestSuites', {
    params: { service },
    cacheable: false,
  });

export const getTestSuite = (id) =>
  NetworkRequest.request('getTestSuite', {
    params: { id },
  });

export const postNewTestSuite = (service, payload) =>
  NetworkRequest.request('postNewTestSuite', {
    params: { service },
    data: payload,
  });

export const postPublishTestSuite = (id, payload) =>
  NetworkRequest.request('postPublishTestSuite', {
    params: { id },
    data: payload,
  });

export const putUpdateTestSuite = (id, payload) =>
  NetworkRequest.request('putUpdateTestSuite', {
    params: { id },
    data: payload,
  });

export const getServiceEnvironments = (service) =>
  NetworkRequest.request('getServiceEnvironments', {
    params: { service },
  });

export const deleteTestSuite = (testsuiteid) =>
  NetworkRequest.request('deleteTestSuite', {
    params: { testsuiteid },
  });

export const putTestSuiteSchedule = (id, payload) =>
  NetworkRequest.request('putTestSuiteSchedule', {
    params: { id },
    data: payload,
  });

export const deleteTestSuiteSchedule = (id) =>
  NetworkRequest.request('deleteTestSuiteSchedule', {
    params: { id },
  });

export const postRunTestSuite = (id, payload) =>
  NetworkRequest.request('postRunTestSuite', {
    params: { id },
    data: payload,
  });

// Jobs queue page Shared APIs CJT and Hosting
export const getSharedExecutions = (service, limit, offset, status) => {
  let query = '';

  if (offset) {
    query = `&offset=${offset}`;
  }

  if (status && status !== 'all') {
    query = `&status=${status}`;
  }
  return NetworkRequest.request('getSharedExecutions', {
    params: { service, limit, query },
    cacheable: false,
  });
};

export const postResubmit = (service, executionid, payload, isNewReport) => {
  const query = isNewReport ? '?newreport=yes' : '';

  return NetworkRequest.request('postResubmit', {
    params: { service, executionid, query },
    data: payload,
  });
};

export const getCJTExecutionStatus = (executionid, transactionid) => {
  return NetworkRequest.request('getCJTExecutionStatus', {
    params: { executionid, transactionid },
    cacheable: false,
  });
};

export const getTestdataExecutionStatus = (executionid, transactionid) => {
  return NetworkRequest.request('getTestDataExecutionStatus', {
    params: { executionid, transactionid },
    cacheable: false,
  });
};

export const getOfferingProducts = (env, usageType) =>
  NetworkRequest.request('getOfferingProducts', {
    params: { env, usageType },
    cacheable: false,
  });

export const getMasterData = () => NetworkRequest.request('getMasterData');

export const getSearchDataStores = (
  category,
  subCategory,
  env,
  store,
  dataAge
) =>
  NetworkRequest.request('getSearchDataStores', {
    params: { env, store, category, subCategory, dataAge },
  });

export const claimTestDataStore = (records) =>
  NetworkRequest.request('claimTestDataStore', {
    data: records,
  });
export const getClaimList = () =>
  NetworkRequest.request('getClaimList', {
    cacheable: false,
  });
