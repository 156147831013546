import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import downloadFile from 'utils/downloadFile';

const TestData = React.memo(function TestData({
  testDataEntries,
  onInputChange,
  onDeleteTestData,
  isDeleteDisabled,
}) {
  const globalFields = useSelector(
    (state) => state.hosting.globalFields.fields
  );

  const handleInput = (key, e) => {
    const newValue = e.target.value;
    onInputChange(key, newValue);
  };

  const testDataElements = Object.entries(testDataEntries);

  if (testDataElements.length === 0) {
    return null;
  }

  const globalFieldsKeys = globalFields
    .map((field) => field.key)
    .filter((key) => Object.keys(testDataEntries).includes(key));

  const handleDownload = (e) => {
    e.preventDefault();

    downloadFile.fromURL(e.target.href);
  };

  return (
    <div className="test-data-group">
      <ReactTooltip id="remove-tooltip" place="top" effect="solid">
        Remove test data clone
      </ReactTooltip>
      <button
        onClick={onDeleteTestData}
        className="test-data-remove"
        disabled={isDeleteDisabled}
        data-tip
        data-for="remove-tooltip"
      >
        +
      </button>
      {testDataElements
        .sort((a, b) => {
          return a[0].localeCompare(b[0]);
        })
        .map(([key, value], index) => (
          <div
            className={testDataElements.length === 1 ? 'col-sm-12' : 'col-sm-6'}
            key={index}
          >
            <div className="form-group form-group-inline">
              <label>{key}: </label>{' '}
              {globalFieldsKeys.includes(key) ? (
                <div class="select-editable">
                  <select
                    value={value}
                    onChange={(e) => {
                      handleInput(key, e);
                    }}
                    className="form-control"
                  >
                    {globalFields
                      .find((field) => field.key === key)
                      .options.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleInput(key, e)}
                    className="form-control"
                  />
                </div>
              ) : value.includes('https://') ? (
                <a href={value} download onClick={handleDownload}>
                  {value.split('/').pop()}
                </a>
              ) : (
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleInput(key, e)}
                  className="form-control"
                />
              )}
            </div>
          </div>
        ))}
    </div>
  );
});

export default TestData;
